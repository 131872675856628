import * as React from "react";
import Layout from "../components/Layout";
import "./tc.sass";

const PricePage = () => {
  return (
    <Layout>
      <div id="tc-container">
        <h2>Bedingungen</h2>
        <p>
          Bedingungen für die Nutzung Gültig ab 10/10/2020 <br /> <br />
          Die vorliegenden allgemeinen Nutzungsbedingungen (nachfolgend "AGB"
          genannt) bezwecken, einen rechtlichen Rahmen für die Bereitstellung
          der Website weely.ch und der Dienste zu schaffen und die Bedingungen
          für den Zugang und die Nutzung der Dienste durch den "Nutzer"
          festzulegen. Die vorliegenden AGB sind auf der Website unter der
          Rubrik "AGB" zugänglich. <br /> <br />
          ARTIKEL 1 : Rechtlicher Hinweis Die Herausgabe und die Leitung der
          Veröffentlichung der Website weely.ch wird durch weely.ch, mit Sitz in
          4 Chemin des Vignes 1295 Tannay, gewährleistet. Telefon-Nummer:
          0794485377 E-Mail Adresse: contact@weely.ch. Die Hoster der weely.ch
          Website sind: - Wix, Hoster der weely.ch Website
          (https://www.wix.com/) - Netlify, Hoster der Webanwendung app.weely.ch
          (https://www.netlify.com/) - Heroku und Amazon Web Services, Hoster
          des app.weely.ch Applikationsservers (https://.heroku.com) - Mlab und
          Amazon Web Services, Hoster der app.weely.ch Applikationsdatenbank
          (https://mlab.com/, https://aws.amazon.com/) <br /> <br />
          ARTIKEL 2: Zugang zur Website Die Website weely.ch ermöglicht dem
          Nutzer den kostenlosen Zugang zu den folgenden Diensten: -
          Bereitstellung eines anpassbaren digitalen Rades Die Website bietet
          die folgenden Dienste an: - Treue-Service - Kundengewinnungsdienst Die
          Website ist für jeden Nutzer mit Internetzugang von jedem Ort aus
          kostenlos zugänglich. Alle Kosten, die dem Nutzer für den Zugriff auf
          den Dienst entstehen (Computerhardware, Software, Internetverbindung
          usw.), gehen zu seinen Lasten. <br /> <br />
          ARTIKEL 3 : Datenerfassung Die Website gewährleistet, dass die
          persönlichen Daten des Nutzers in Übereinstimmung mit der
          schweizerischen Datenschutzgesetzgebung erhoben und verarbeitet
          werden. Nach dem schweizerischen Gesetz über die Datenerfassung hat
          der Nutzer das Recht auf Zugang, Berichtigung, Löschung und
          Widerspruch seiner persönlichen Daten. Dies ist möglich, indem er
          weely.ch per E-Mail benachrichtigt. <br /> <br />
          ARTIKEL 4: Geistiges Eigentum Die Marken, Logos, Zeichen sowie der
          gesamte Inhalt der Website (Texte, Bilder, Töne...) sind durch das
          Gesetz über geistiges Eigentum und insbesondere durch das Urheberrecht
          geschützt. Der Nutzer muss für jede Vervielfältigung, Veröffentlichung
          oder Kopie der verschiedenen Inhalte die vorherige Genehmigung der
          Website einholen. Der Nutzer verpflichtet sich, die Inhalte der
          Website ausschließlich privat zu nutzen; jede Nutzung zu kommerziellen
          oder Werbezwecken ist strengstens untersagt. Jede vollständige oder
          teilweise Darstellung dieser Website durch irgendein Verfahren ohne
          die ausdrückliche Genehmigung des Betreibers der Website stellt eine
          Verletzung dar, die nach der schweizerischen Gesetzgebung über das
          geistige Eigentum strafbar ist. In Übereinstimmung mit der
          schweizerischen Gesetzgebung zum geistigen Eigentum wird darauf
          hingewiesen, dass der Nutzer, der den geschützten Inhalt reproduziert,
          kopiert oder veröffentlicht, den Autor und die Quelle angeben muss.{" "}
          <br /> <br />
          ARTIKEL 5: Verantwortlichkeit Die Quellen der auf der Website weely.ch
          veröffentlichten Informationen werden als zuverlässig angesehen, aber
          die Website garantiert nicht, dass sie frei von Mängeln, Fehlern oder
          Auslassungen sind. Die zur Verfügung gestellten Informationen werden
          als Hinweis und allgemein ohne vertraglichen Wert präsentiert. Trotz
          regelmässiger Aktualisierung kann die Website weely.ch nicht für
          Änderungen der administrativen und rechtlichen Bestimmungen nach der
          Veröffentlichung verantwortlich gemacht werden. Ebenso wenig kann die
          Website für die Nutzung und Auslegung der auf dieser Website
          enthaltenen Informationen verantwortlich gemacht werden. Die Website
          weely.ch kann nicht für Viren verantwortlich gemacht werden, die den
          Computer oder eine andere Computerausrüstung des Internetnutzers nach
          der Nutzung, dem Zugriff oder dem Herunterladen von dieser Website
          infizieren könnten. Die Verantwortung der Website kann im Falle von
          höherer Gewalt oder unvorhersehbaren und unüberwindbaren Tatsachen
          eines Dritten nicht übernommen werden. oder Die Nutzung der Website
          erfolgt auf Ihr eigenes Risiko. Die Website wird Ihnen "wie besehen"
          und "wie verfügbar" angeboten. Daher bietet weely keine Garantie.
          Insbesondere garantiert weely nicht, dass (i) die Website und die auf
          ihr angebotenen Produkte/Dienstleistungen Ihren Anforderungen
          entsprechen; (ii) die Website und ihr Inhalt vollständig, genau,
          zuverlässig und aktuell sind und nicht die Rechte Dritter verletzen;
          (iii) der Zugang zur Website nicht unterbrochen wird oder fehlerfrei
          ist oder garantiert virenfrei ist; (iv) Fehler behoben werden; (v) die
          Website sicher ist; oder (vi) jegliche Ratschläge oder Meinungen, die
          von weely über diese Website abgegeben werden, richtig und zuverlässig
          sind. Alle Informationen auf der Website können ohne vorherige
          Ankündigung geändert oder gelöscht werden. Oder IM VOLLSTÄNDIGEN
          GESETZLICHEN UMFANG IST JEDE HAFTUNG VON weely, SEINER ORGANISATIONEN,
          SEINER HILFSMITARBEITER UND VERTRETER, DIE DURCH IHREN ZUGANG ZUR SITE
          ODER IHRE NUTZUNG DER SITE ENTSTEHT, AUSGESCHLOSSEN. ALS VORAUSSETZUNG
          FÜR DIE NUTZUNG DER WEBSITE ERKLÄREN SIE SICH DAMIT EINVERSTANDEN,
          WEELY, SEINE ORGANISATIONEN, ASSISTENTEN UND VERTRETER VON ALLEN
          SCHÄDEN (EINSCHLIESSLICH GERICHTS- UND RECHTSANWALTSKOSTEN)
          FREIZUSTELLEN, DIE SICH AUS IHREM ZUGRIFF AUF ODER IHRER NUTZUNG DER
          WEBSITE ERGEBEN KÖNNEN.
          <br /> <br /> ARTIKEL 6: Links zu anderen Websites Links auf der
          Website können Sie außerhalb unserer Netzwerke und Systeme führen,
          insbesondere zu den Websites unserer Partner. Weely übernimmt keine
          Verantwortung für den Inhalt, die Richtigkeit oder den Betrieb dieser
          Websites Dritter. Diese Links werden in gutem Glauben zur Verfügung
          gestellt, und Weely kann nicht für den Inhalt der Websites Dritter, zu
          denen ein Link besteht, verantwortlich gemacht werden. Die Aufnahme
          eines Links zu anderen Websites bedeutet nicht, dass weely sie
          billigt. Wir empfehlen Ihnen, die rechtlichen Hinweise und den
          Datenschutzhinweis aller anderen Websites, die Sie besuchen,
          sorgfältig zu lesen.
          <br /> <br /> ARTIKEL 7: Cookies Der Nutzer wird darüber informiert,
          dass bei seinen Besuchen auf der Website automatisch ein Cookie in
          seinem Browser installiert werden kann. Cookies sind kleine Dateien,
          die von Ihrem Browser vorübergehend auf der Festplatte des Computers
          des Nutzers gespeichert werden und die für die Nutzung der Website
          weely.ch erforderlich sind. Cookies enthalten keine persönlichen
          Informationen und können nicht zur Identifizierung von Personen
          verwendet werden. Ein Cookie enthält eine eindeutige, zufällig
          generierte und daher anonyme Kennung. Einige Cookies verfallen am Ende
          des Besuchs des Benutzers, andere bleiben bestehen. Die in den Cookies
          enthaltenen Informationen werden verwendet, um die Website weely.ch zu
          verbessern. Durch das Surfen auf der Website akzeptiert der Benutzer
          die Cookies. Der Benutzer muss jedoch seine Zustimmung zur Verwendung
          bestimmter Cookies geben. Wenn der Benutzer nicht zustimmt, wird er
          darüber informiert, dass bestimmte Funktionen oder Seiten verweigert
          werden können. Der Nutzer kann diese Cookies über die Parameter seines
          Browsers deaktivieren. <br /> <br />
          ARTIKEL 9: Verarbeitung von Mitteilungen an weely - Sicherheit von
          E-Mails Mitteilungen, die Sie über das Internet an weely senden,
          werden nicht als vertraulich betrachtet. Wenn Sie mit uns per E-Mail
          kommunizieren, müssen Sie berücksichtigen, dass der Schutz von
          E-Mail-Nachrichten, die über das Internet übertragen werden, nicht
          gewährleistet ist. Indem Sie wichtige, unverschlüsselte Nachrichten
          per E-Mail versenden, akzeptieren Sie daher die Risiken dieser
          Unsicherheit.
          <br /> <br /> ARTIKEL 10: Verbotene Aktivitäten Weely behält sich das
          Recht vor, ohne Vorankündigung jede Handlung zu verbieten (z. B. durch
          Löschen des betreffenden Inhalts oder durch Einschränkung/Verbot des
          Zugangs zur gesamten oder zu einem Teil der Website), die wir für
          unangemessen halten und/oder die wir für gesetzeswidrig oder
          unangemessen halten, einschließlich (aber nicht beschränkt auf)
          Betrugsversuche und Missbrauch, die den Sicherheitssystemen von Weely
          (oder Dritten) schaden können. Der Nutzer, dessen Zugang zur Website
          gekündigt wurde, ist nicht mehr berechtigt, sich auf der Website zu
          registrieren, weder unter seinem eigenen Namen noch unter einem
          anderen Namen, es sei denn, weely hat vorher schriftlich seine
          Zustimmung erteilt.
          <br /> <br /> ARTIKEL 11: Teilweise Nichtigkeit Jede Bestimmung der
          Nutzungsbedingungen, die ganz oder teilweise gegen zwingendes
          schweizerisches Recht verstößt, ist trennbar, und die vollständige
          oder teilweise Nichtigkeit einer solchen Klausel berührt weder die
          Gültigkeit der übrigen Klausel noch die der anderen Klauseln der
          Nutzungsbedingungen. Sollte eine Bestimmung der Nutzungsbedingungen
          ganz oder teilweise unwirksam sein, so ist sie durch eine Bestimmung
          zu ersetzen, deren wirtschaftliche und rechtliche Wirkungen denen der
          unwirksamen Bestimmung möglichst nahe kommen. <br /> <br />
          ARTIKEL 12: Sonstiges Die Nutzungsbedingungen stellen die gesamte
          Vereinbarung zwischen weely und Ihnen in Bezug auf den Zugang und die
          Nutzung der Website und des Inhalts dar. Die Tatsache, dass weely
          Verstöße von Ihnen gegen eine Ihrer Verpflichtungen aus den
          Nutzungsbedingungen toleriert oder die Anwendung eines Rechts, das
          weely durch die Nutzungsbedingungen oder durch das Gesetz gewährt
          wird, nicht verlangt, kann nicht als Verzicht von weely auf die
          Inanspruchnahme dieses Rechts ausgelegt werden. <br /> <br />
          ARTIKEL 13 : Anwendbares Recht und Gerichtsstand Auf den vorliegenden
          Vertrag ist die schweizerische Gesetzgebung anwendbar. Sollte es nicht
          gelingen, einen Streit zwischen den Parteien gütlich beizulegen, sind
          ausschließlich die Schweizer Gerichte zuständig. Bei Fragen zur
          Anwendung der vorliegenden AGB können Sie sich an den Herausgeber
          unter den in ARTIKEL 1 genannten Koordinaten wenden. Eventuell zu
          integrierende Ideen: -Teilnahme an den Spielen unterliegt den
          Spielregeln, die Bestandteil der Nutzungsbedingungen sind. Alle
          Informationen und Unterlagen auf der Website (sowie auf den Seiten,
          die wir in den sozialen Netzwerken animieren) werden nur zu
          Informationszwecken und mit dem Ziel der Förderung unserer Produkte
          und Dienstleistungen präsentiert. Die dargestellten Informationen und
          Materialien stellen kein Angebot von Produkten und/oder
          Dienstleistungen von uns, unseren Partnern oder Dritten dar und
          sollten nicht als Ratschläge oder Anweisungen zur Verwendung unserer
          Produkte und Dienstleistungen betrachtet werden. Wir unternehmen alle
          angemessenen Anstrengungen, um sicherzustellen, dass die Informationen
          auf der Website (sowie auf den von uns gehosteten Seiten in sozialen
          Netzwerken) korrekt und vollständig sind. Wir übernehmen jedoch keine
          Haftung, wenn dies nicht der Fall ist. Jedes Vertrauen, das Sie auf
          solche Informationen setzen, geschieht auf Ihr eigenes Risiko. Sie
          erklären sich damit einverstanden, dass es in Ihrer Verantwortung
          liegt, sich über alle Änderungen des Inhalts der Website zu
          informieren. Jegliche Haftung von weely in dieser Hinsicht ist
          ausgeschlossen. Es liegt in der Verantwortung des Kunden, sich über
          mögliche Einschränkungen bei der Verwendung der Produkte, die er
          bestellen möchte, zu informieren.
        </p>
      </div>
    </Layout>
  );
};

export default PricePage;
